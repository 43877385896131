class Tour {
  constructor(data) {
    this.id = data.id;
    this.name = data.name || '';
    this.title = data.title || '';
    //this.metadescription = data.metadescription || '';
    this.status = data.status || 'inactive';
    this.includeATVs = data.includeATVs || 'no';
    this.deleted = data.deleted || 0;
    this.includedExtras = data.includedExtras || [];
    this.createdDate = data.createdDate;
    // this.pictureTour = pictureTour || '';
  }

}
var DataConverter = {
  // Firestore data converter
  toFirestore: function(tour) {
    return {
      name: tour.name,
      title: tour.title,
      //metadescription: tour.metadescription,
      status: tour.status,
      deleted: tour.deleted,
      includeATVs: tour.includeATVs,
      createdDate: tour.createdDate,
      // pictureTour: tour.pictureTour,
    }
  },
  fromFirestore: function(snapshot, options) {
    const data = snapshot.data(options);
    data.id = snapshot.id;
    return new Tour(data)
  }
}

export { Tour, DataConverter }