<template>
  <div>
    <loading :active.sync="active" 
          :can-cancel="true" 
          :is-full-page="fullPage || false"></loading>
  </div>
</template>
<script>
  export default {
    name:"Loading",
    props:['active', 'fullPage'],
    data() {
      return {
      }
    }
  }
</script>