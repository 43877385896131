import ReservationModel from "@/models/Reservation";
import {NumberFormat} from "@/helpers/NumberFormat";

class Reservation {
  id;
  constructor(data) {
    this.id = data.id || '';
    this.tourId = data.tourId || '';
    this.code = data.code || 0
    this.adults = data.adults || 0
    this.children = data.children || 0
    this.elderlies = data.elderlies || 0;
    this.infants = data.infants || 0
    this.pickupDateTime = data.pickupDateTime || '';
    this.createdDate = data.createdDate || '';
    this.currency = data.currency || '';
    this.customer = data.customer || '';
    this.customerEmail = data.customerEmail || '';
    this.customerLastname = data.customerLastname || '';
    this.customerName = data.customerName || '';
    this.date = data.date || '';
    this.exchangeRate = data.exchangeRate || '';
    this.lang = data.lang || '';
    this.paymentMethod = data.paymentMethod || '';
    this.paymentStatus = data.paymentStatus || '';
    this.status = data.status || '';
    this.subtotal = data.subtotal || '';
    this.total = data.total || '';
    this.tour = data.tour || '';
    this.rate = data.rate || '';
    this.typeRate = data.typeRate || '';
    this.user = data.user;
    this.userUID = data.userUID;/////
    this.userName = data.userName;
    this.confirmationInfo = data.confirmationInfo || false;
    this.confirmationDescription = data.confirmationDescription || '';

    this.coupon = data.coupon || null;
    this.totalCoupon = data.totalCoupon || null;
    this.couponNational = data.couponNational || null;
    this.totalCouponNational = data.totalCouponNational || null;
    this.couponExtra = data.couponExtra || null;
    this.totalCouponExtra = data.totalCouponExtra || null;
    this.extras = data.extras || [];
    this.totalExtras = data.totalExtras || null;
    this.subtotalExtras = data.subtotalExtras || null;
    
    this.transportation = data.transportation || null;
    this.hotel = data.hotel || null;
    this.room = data.room || null;
    this.zone = data.zone || null;
    this.responsives = data.responsives || null;
    this.childrenNames = data.childrenNames || null;
    this.panel = data.panel || null;

    this.landingId = data.landingId || null;
    this.landing = data.landing || null;
    this.landingTitle = data.landingTitle || null;
    this.landingUrl = data.landingUrl || null;
    this.landingLang = data.landingLang || null;
    this.landingImg = data.landingImg || null;

    this.payments = null;
    this.observerPayments = null;
    this.balance = null;
    
    this.paymentsCompleted = true
    //divers fields
    if (data.panel === 'divers') {
      this.discount = data.discount || null;
      this.immersions = data.immersions || 0
      this.guides = data.guides
      this.nameGuides = data.nameGuides
      this.lastReservation = data.lastReservation
    }
  }
  panelFormated() {
    if (this.panel === 'website') {
      return 'Web'
    }
    if (this.panel === 'taquilla') {
      return 'Taquilla'
    }
    if (this.panel === 'divers') {
      return 'Buzos'
    }
    return ''
  }
  setPayments() {
    const reservationModel = new ReservationModel
    let totalPayments = 0
    const currency = this.currency
    this.observerPayments = reservationModel.getPayments(this.id).onSnapshot(snap => {
      this.payments = [];
      totalPayments = 0
      snap.forEach(doc => {
        const payment = doc.data()
        if (this.paymentsCompleted) {
          this.paymentsCompleted = this.paymentIsCompleted(payment)
        }
        if (payment.gatewayPayment != 'courtesy') {
          if (currency == payment.getCurrency()) {
            totalPayments += payment.getPaidAmount()
          } else {
            totalPayments += payment.getPaidAmount() * payment.getExchangeRate()
          }
        }
        this.payments.push(payment)
      })
      console.log('this.paymentsCompleted', this.paymentsCompleted)
      this.balance = NumberFormat.round(this.total, 2) - NumberFormat.round(totalPayments, 2)
    })
  }
  paymentIsCompleted(payment) {
    if (payment.gatewayPayment == 'cash' || payment.gatewayPayment == 'paypal') {
      return true
    }
    if (payment.gatewayPayment == 'transfer-deposit') {
      if (payment.transferType && payment.transactionId && payment.image) {
        return true
      }
      return false
    } else {
      if (payment.transactionId && payment.image) {
        return true
      }
      return false
    }
  }
}
var DataConverter = {
  toFirestore: function(reservation) {
    return {
      id: reservation.id,
      code: reservation.code,
      adults: reservation.adults,
      children: reservation.children,
      createdDate: reservation.createdDate,
      currency: reservation.currency,
      customer: reservation.customer,
      customerEmail: reservation.customerEmail,
      customerLastname: reservation.customerLastname,
      customerName: reservation.customerName,
      date: reservation.date,
      elderlies: reservation.elderlies,
      exchangeRate: reservation.exchangeRate,
      infants: reservation.infants,
      lang: reservation.lang,
      paymentMethod: reservation.paymentMethod,
      paymentStatus: reservation.paymentStatus,
      status: reservation.status,
      subtotal: reservation.subtotal,
      total: reservation.total,
      tour: reservation.tour,
      rate: reservation.rate,
      typeRate: reservation.typeRate,
      userUID: reservation.userUID,////
      confirmationInfo: reservation.typeRate || false,
    }
  },
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    data.id = snapshot.id;
    return new Reservation(data)
  }
}
export {Reservation, DataConverter} 