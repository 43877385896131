class Rate {
  id;
  moment;
  #ref;
  constructor(data) {
    this.id = data.id;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.adult = data.adult;
    this.elderly = data.elderly;
    this.adultShared = data.adultShared;
    this.elderlyShared = data.elderlyShared;
    this.child = data.child;
    this.infant = data.infant;
    this.type = data.type;
  }
  set moment(moment) {
    this.moment = moment;
  }
  set ref(ref) {
    this.#ref = ref;
  }
  formatStartDate(format) {
    format = format || 'DD-MM-YYYY'
    return this.moment(this.startDate.toDate()).format(format);
  }
  formatEndDate(format) {
    format = format || 'DD-MM-YYYY'
    return this.moment(this.endDate.toDate()).format(format);
  }
}
var DataConverter = {
  toFirestore: function(rate) {
    return {
      startDate : rate.startDate,
      endDate : rate.endDate,
      adult : rate.adult,
      elderly : rate.elderly,
      child : rate.child,
      infant : rate.infant,
      type : rate.type
    }
  },
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    data.id = snapshot.id
    return new Rate(data)
  }
}
export {Rate, DataConverter} 