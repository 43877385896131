import BaseModel from './BaseModel';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Tour as TourData, DataConverter as TourDataConverter } from '@/FirestoreDataConverters/Tour.js';
import { DataConverter as RateDataConverter } from '@/FirestoreDataConverters/Rate.js';

export default class Tour extends BaseModel {
  constructor(options) {
    super(options);
  }
  findByTour(id) {
    return this.db.collection('tours').doc(id).withConverter(TourDataConverter);
  }
  update(id, obj) {
    return this.db.collection('tours').doc(id).update(obj);
  }
  all() {
    return this.db.collection('tours').where("deleted", "==", 0).withConverter(TourDataConverter);
  }
  getActives() {
    return this.db.collection('tours')
    .where("status", "==", 'active')
    .where("deleted", "==", 0)
    .withConverter(TourDataConverter);
  }
  addTour(obj) {
    return this.db.collection('tours').withConverter(TourDataConverter).add(obj);
  }
  delete(id) {
    return this.db.collection('tours').doc(id).delete();
  }
  //   delete(id) {
  //     return new Promise((resolve, reject) => {
  //         this.db.collection('tours').doc(id).get().then(snapDoc => {
  //             let batch = this.db.batch();
  //             let promises = [];
  //             promises.push(snapDoc.ref.collection('image').get())
  //             promises.push(snapDoc.ref.collection('imageActividades').get())
  //             promises.push(snapDoc.ref.collection('localRates').get())
  //             promises.push(snapDoc.ref.collection('regularRates').get())
  //             promises.push(snapDoc.ref.collection('tourLanguages').get())
  //             promises.push(snapDoc.ref.collection('transport').get())
  //             Promise.all(promises).then(values => {
  //                     let image = values[0];
  //                     let imageActividades = values[1];
  //                     let localRates = values[2];
  //                     let regularRates = values[3];
  //                     let tourLanguages = values[4];
  //                     let transport= values [5];
  //                     tourLanguages.forEach(doc => {
  //                         batch.delete(this.db.collection('tours').doc(id).collection('tourLanguages').doc(doc.id));
  //                     })
  //                     let promisesImages = [];
  //                     images.forEach(doc => {
  //                         promisesImages.push(firebase.storage().ref(doc.data().fullpath).delete())
  //                         batch.delete(this.db.collection('cupones').doc(id).collection('cuponesImages').doc(doc.id));
  //                     })
  //                     Promise.all(promisesImages).then(() => {
  //                             batch.delete(snapDoc.ref);
  //                             batch.commit().then(() => resolve('doing'))
  //                                 .catch(error => reject(error))
  //                         })
  //                         .catch(error => reject(error))
  //                 })
  //                 .catch(error => reject(error))
  //         })
  //     })
  // }


  searchLocalRatesAfterEndDate(tourId, date) {
    return this.db.collection('tours').doc(tourId).collection('localRates').where('endDate', '>=', date).orderBy('endDate', 'desc').withConverter(RateDataConverter);
  }
  searchRegularRatesAfterEndDate(tourId, date) {
    return this.db.collection('tours').doc(tourId).collection('regularRates').where('endDate', '>=', date).orderBy('endDate', 'desc').withConverter(RateDataConverter);
  }
  add(data, language) {
    return new Promise((resolve, reject) => {
      data.createdDate = firebase.firestore.Timestamp.fromDate(new Date);
      this.db.collection('tours').withConverter(TourDataConverter)
      .add(new TourData(data)).then(docRef => {
        docRef.collection('tourLanguages').doc(language.id).set({
          name: data.name,
          title: data.title,
          metadescription: data.metadescription,
          language: language.name,
          description: data.description,
          important: data.important,
          include: data.include,
          recommendation: data.recommendation,
        }).then(() => resolve(docRef))
      })
      .catch(error => reject(error))
    })
  }
  /**
   * Busca tarifas del tour donde startDate este entre un rango de fechas (startDate y endDate)
   * @param  string tourId    referencia al id del doc
   * @param  firebase.firestore.Timestamp startDate fecha inferior
   * @param  firebase.firestore.Timestamp endDate   fecha superior
   * @return firebase query
   */
  /*searchLocalRatesStartDateBetweenDates(tourId, startDate, endDate) {
    return this.db.collection('tours').doc(tourId).collection('localRates').where('startDate', '>=', startDate).where('startDate', '<=', endDate).withConverter(RateDataConverter);
  }*/
  /**
   * Busca tarifas del tour donde endDate este entre un rango de fechas (startDate y endDate)
   * @param  string tourId    referencia al id del doc
   * @param  firebase.firestore.Timestamp startDate fecha inferior
   * @param  firebase.firestore.Timestamp endDate   fecha superior
   * @return firebase query
   */
  /*searchLocalRatesEndDateBetweenDates(tourId, startDate, endDate) {
    return this.db.collection('tours').doc(tourId).collection('localRates').where('endDate', '>=', startDate).where('endDate', '<=', endDate).withConverter(RateDataConverter);
  }*/
  /**
   * Busca tarifas del tour donde startDate este entre un rango de fechas (startDate y endDate)
   * @param  string tourId    referencia al id del doc
   * @param  firebase.firestore.Timestamp startDate fecha inferior
   * @param  firebase.firestore.Timestamp endDate   fecha superior
   * @return firebase query
   */
  /*searchRegularRatesStartDateBetweenDates(tourId, startDate, endDate) {
    return this.db.collection('tours').doc(tourId).collection('regularRates').where('startDate', '>=', startDate).where('startDate', '<=', endDate).withConverter(RateDataConverter);
  }*/
  /**
   * Busca tarifas del tour donde endDate este entre un rango de fechas (startDate y endDate)
   * @param  string tourId    referencia al id del doc
   * @param  firebase.firestore.Timestamp startDate fecha inferior
   * @param  firebase.firestore.Timestamp endDate   fecha superior
   * @return firebase query
   */
  /*searchRegularRatesEndDateBetweenDates(tourId, startDate, endDate) {
    return this.db.collection('tours').doc(tourId).collection('regularRates').where('endDate', '>=', startDate).where('endDate', '<=', endDate).withConverter(RateDataConverter);
  }*/
  seachLocalRatesAfterDate(tourId, date) {
      return this.db.collection('tours').doc(tourId).collection('localRates').where('endDate', '>=', date).withConverter(RateDataConverter);
  }
  seachRegularRatesAfterDate(tourId, date) {
      return this.db.collection('tours').doc(tourId).collection('regularRates').where('endDate', '>=', date).withConverter(RateDataConverter);
  }
  deleteRate(tourId, rateId, rateType) {
    if (rateType == 'local') {
        return this.db.collection('tours').doc(tourId).collection('localRates').doc(rateId).delete();
    }
    return this.db.collection('tours').doc(tourId).collection('regularRates').doc(rateId).delete();
  }
  async deleteUrls(tourId) {
    console.log('tourId');
    this.db.collection('tours').doc(tourId).collection('tourUrls').get().then(snap => {
      snap.forEach(async (item) => {
        await item.ref.update({'deleted':1})
      })
    })
    //collectionGroup('tourUrls').where('tourId', '==', tourId).where('language', '==', lang);

  }
  findUrlByTour(tourId, lang) {
    return this.db.collectionGroup('tourUrls').where('tourId', '==', tourId).where('deleted', '==', 0).where('language', '==', lang);
  }
  findUrl(fullUrl/*, lang*/) {
    return this.db.collectionGroup('tourUrls').where('fullUrl', '==', fullUrl).where('deleted', '==', 0)//.where('language', '==', lang);
  }
}