import _ from 'lodash';
import {NumberFormat} from "@/helpers/NumberFormat";
import moment from 'moment'

class Payment {
  id;
  constructor(data) {
    this.setProps(data);
    if (typeof data.gatewayPayment !== 'undefined') {
      this.gatewayPayment = data.gatewayPayment
    } else {
      this.gatewayPayment = 'paypal'
    }
  }
  setProps(data) {
    _.each(data, (value, key) => {
      this[key] = value;
    });
  }
  getGatewayName() {
    switch (this.gatewayPayment) {
      case 'stripe':
        return 'Tarjeta de crédito/débito'
      case 'paypal':
        return 'Paypal'
      case 'paypal-rq':
        return 'Paypal solicitud'
      case 'evopay-rq':
        return 'Evopay solicitud'
      case 'clip-terminal':
        return 'Clip Terminal'
      case 'clip-rq':
        return 'Clip solicitud'
      case 'tpv-bank':
        return 'Terminal bancaria'
      case 'bank-cheque':
        return 'Cheque'
      case 'cash':
        return 'Efectivo'
      case 'transfer-deposit':
        return 'Transferencia/Deposito'
      case 'courtesy':
        return 'Cortesia por: ' + this.authorizedBy;
      default :
        return 'No definido';
    }
  }
  getFee() {
    switch (this.gatewayPayment) {
      case 'stripe':
        return 0;
      case 'paypal':
        return parseFloat(this.mc_fee);
      case 'paypal-rq':
      case 'evopay-rq':
      case 'clip-terminal':
      case 'clip-rq':
      case 'tpv-bank':
      case 'bank-cheque':
      case 'cash':
      case 'transfer-deposit':
        return NumberFormat.round(parseFloat(this.fee), 2)
        // return NumberFormat.format(parseFloat(this.fee), 2, ',', '.');
      case 'courtesy':
        return '0';
      default :
        return 0;
    }
  }
  getPaidAmount() {
    switch (this.gatewayPayment) {
      case 'stripe':
        return parseFloat(this.data.object.amount) / 100;
      case 'paypal':
        return NumberFormat.round(parseFloat(this.mc_gross), 2);
        // return NumberFormat.format(parseFloat(this.mc_gross), 2, ',', '.');
      case 'paypal-rq':
      case 'evopay-rq':
      case 'clip-terminal':
      case 'clip-rq':
      case 'tpv-bank':
      case 'bank-cheque':
      case 'cash':
      case 'transfer-deposit':
        return NumberFormat.round(parseFloat(this.amount), 2);
      case 'courtesy':
        return '0';
      default :
        return 0;
    }
  }
  getTotalAmount() {
    let totalAmount;
    switch (this.gatewayPayment) {
      case 'stripe':
        return parseFloat(this.data.object.amount) / 100;
      case 'paypal':
        totalAmount = parseFloat(this.mc_gross) - parseFloat(this.mc_fee)
        return NumberFormat.round(parseFloat(totalAmount))
        // return NumberFormat.format(parseFloat(totalAmount), 2, ',', '.')
      case 'paypal-rq':
      case 'evopay-rq':
      case 'clip-terminal':
      case 'clip-rq':
      case 'tpv-bank':
      case 'bank-cheque':
      case 'cash':
      case 'transfer-deposit':
        totalAmount = parseFloat(this.amount) - parseFloat(this.fee);
        return NumberFormat.round(parseFloat(totalAmount), 2, ',', '.')
        // return NumberFormat.format(parseFloat(totalAmount), 2, ',', '.')
      case 'courtesy':
        return '0';
      default :
        return 0;
    }
  }
  getExchangeRateConverted() {
    switch (this.gatewayPayment) {
      case 'stripe':
        return 0
      case 'paypal':
        return 0
      case 'paypal-rq':
      case 'evopay-rq':
      case 'clip-terminal':
      case 'clip-rq':
      case 'tpv-bank':
      case 'bank-cheque':
      case 'cash':
      case 'transfer-deposit':
        return NumberFormat.round((1 / parseFloat(this.exchangeRate)), 2, ',', '.')
        // return NumberFormat.format((1 / parseFloat(this.exchangeRate)), 2, ',', '.')
      case 'courtesy':
        return '0';
      default :
        return 0;
    }
  }
  getFormatedDate(format) {
    const strFormat = format || 'HH:mm DD-MM-YYYY'
    switch (this.gatewayPayment) { 
      case 'stripe':
        return moment.unix(this.created).format(strFormat)
      case 'paypal':
        return moment(this.payment_date.slice(0, -4), 'HH:mm:ss MMM DD, YYYY').format(strFormat)
        // HH:MM:SS Mmm DD, YYYY PDT
      case 'paypal-rq':
      case 'evopay-rq':
      case 'clip-terminal':
      case 'clip-rq':
      case 'tpv-bank':
      case 'bank-cheque':
      case 'cash':
      case 'transfer-deposit':
      case 'courtesy':
        return moment.unix(this.date.seconds).format(strFormat)
      default :
        return this.date
    }
  }
  /**
   * Devuelve el tipo de cambio
   *
   */
  getExchangeRate() {
    switch (this.gatewayPayment) {
      case 'stripe':
        return 1
      case 'paypal':
        return 1
      case 'paypal-rq':
      case 'evopay-rq':
      case 'clip-terminal':
      case 'clip-rq':
      case 'tpv-bank':
      case 'bank-cheque':
      case 'cash':
      case 'transfer-deposit':
        return parseFloat(this.exchangeRate)
      case 'courtesy':
        return '0';
      default :
        return 0;
    }
  }
  getCurrency() {
    switch (this.gatewayPayment) {
      case 'stripe':
        return this.data.object.currency.toLowerCase()
      case 'paypal':
        return this.mc_currency.toLowerCase()
      case 'paypal-rq':
      case 'evopay-rq':
      case 'clip-terminal':
      case 'clip-rq':
      case 'tpv-bank':
      case 'bank-cheque':
      case 'cash':
      case 'transfer-deposit':
        return this.currency.toLowerCase()
      case 'courtesy':
        return '';
      default :
        return '';
    }
  }
  getTransactionId() {
    switch (this.gatewayPayment) {
      case 'stripe':
        return this.data.object.id
      case 'paypal':
        return this.txn_id
      case 'paypal-rq':
      case 'evopay-rq':
      case 'clip-terminal':
      case 'clip-rq':
      case 'tpv-bank':
      case 'bank-cheque':
      case 'transfer-deposit':
        return this.transactionId
      case 'cash':
      case 'courtesy':
        return '';
      default :
        return '';
    }
  }
}
var DataConverter = {
  toFirestore: function(item) {
    return {
      gatewayPayment:item.gatewayPayment
    }
  },
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    data.id = snapshot.id
    return new Payment(data)
  }
}
export {Payment, DataConverter} 