import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from 'moment'
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// https://github.com/bpampuch/pdfmake/blob/master/src/standardPageSizes.js
export default class {
  constructor(data) {
    this.filters = data.filters
    this.data = data.data
    this.date = data.date
    this._build()
  }
  async create() {
    //var win = window.open('', '_blank');
    this.dd = {
      pageSize: 'legal',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [ 40, 90, 10, 40 ],
      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'portrait',
      header: [
        {
          image:await this.getBase64ImageFromURL(
            process.env.VUE_APP_BASE_URL+require('@/assets/logo.png')
          ),
          width: 130,
          absolutePosition: {
            x: 40,
            y: -5
          }
        },
        {
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 30,
              h: 1008,//legal
              //r: 5,//radius
              //dash: { length: 5 },
              // lineWidth: 10,
              lineColor: '#ff8300',
              color: '#ff8300',
            },
            {
              type: 'rect',
              x: 370,
              y: 0,
              w: 200,
              h: 70,
              lineColor: '#005f31',
              color: '#005f31',
            },
            {
              type: 'line',
              x1: 60, y1: 80,
              x2: 570, y2: 80,
              lineWidth: 1,
              lineColor:"#c3c3c3"
            },
            // {
            //   type: 'line',
            //   x1: 40, y1: 80,
            //   x2: 260, y2: 80,
            //   lineWidth: 10,
            //   lineCap: 'round',
            //   lineColor:"#c3c3c3"
            // },
          ],
          absolutePosition:{
            x: 0,
            y: 0,
          },
        },
        {
          text: 'Fecha: '+this.date.format('DD-MM-YYYY'),
          style: 'reservationId',
          absolutePosition:{x: 385, y:  17}
        }
      ],
      content: this.content,
      styles: {
        reservationId: {
          fontSize:20,
          bold: true,
          color: '#fff'
        },
      }
    }
    //pdfMake.createPdf(this.dd).open({}, win);
    return pdfMake.createPdf(this.dd)
  }
  _build() {
    this.content = [
      {text:'Filtros', margin: [0, 0, 0, 5]},
      {
        style: 'tableExample',
        table: {
          widths: ['*', '*', '*', '*', '*', '*'],
          body: [
            [
              this._tableCell('Fecha Inicio'),
              this._tableCell('Fecha fin'),
              this._tableCell('Método de pago'),
              this._tableCell('Tour'),
              this._tableCell('Panel'),
              this._tableCell('Tarifa'),
            ],
            [
              this._tableCell(this.filters.startDate.format('DD-MM-YYYY')),
              this._tableCell(this.filters.endDate.format('DD-MM-YYYY')),
              this._tableCell(this.filters.gatewayPayment.name || 'Todos'),
              this._tableCell(this.filters.tour.name || 'Todos'),
              this._tableCell(this.filters.panel.name || 'Todos'),
              this._tableCell(this.filters.rate.name || 'Todos'),
              //this._tableCell(this.filters.status.name || 'Todos'),
            ]
          ]
        }
      },
      '',
      '',
      // [left, top, right, bottom]
      {text:'Resultados', margin: [0, 10, 0, 5]},
      {
        style: 'tableExample',
        //color: '#444',
        table: {
          //widths: ['10', '20', '*', '*', '*', '*', '*', '*', '*'],
          body: [
            [
              this._tableCell('No.'),
              this._tableCell('Panel'),
              this._tableCell('Método'),
              this._tableCell('MXN'),
              this._tableCell('USD'),
              this._tableCell('ID de transacción'),
              this._tableCell('Fecha de pago'),
              this._tableCell('Fecha de tour'),
              this._tableCell('Reservación'),
            ],
            ...this._buildBody(),
            [
              this._tableCell(''),
              this._tableCell(''),
              this._tableCell('Totales'),
              this._tableCell(this.data.totalMXN),
              this._tableCell(this.data.totalUSD),
              this._tableCell(''),
              this._tableCell(''),
              this._tableCell(''),
              this._tableCell(''),
            ],
          ]
        }
      }
    ]
  }
  _tableCell(text) {
    return {
      text:text,
      borderColor: ['#c3c3c3', '#c3c3c3', '#c3c3c3', '#c3c3c3']
    }
  }
  _buildBody() {
    let rows = []
    const panelsNames = {
      website: 'Web',
      taquilla: 'Taquilla',
    }
    let counter = 0
    for (let key in this.data.payments) {
      const i = this.data.payments[key]
      counter++
      rows.push([
        this._tableCell(counter),
        this._tableCell(panelsNames[i.panel]),
        this._tableCell(i.payment.getGatewayName()),
        this._tableCell(i.payment.getCurrency() == 'mxn' ? i.payment.getPaidAmount() : ''),
        this._tableCell(i.payment.getCurrency() == 'usd' ? i.payment.getPaidAmount() : ''),
        this._tableCell(i.payment.getTransactionId()),
        this._tableCell(i.payment.getFormatedDate()),
        this._tableCell(moment.unix(i.date.seconds).format('DD-MM-YYYY')),
        this._tableCell(i.reservationCode),
      ])
    }
    return rows
  }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }
}