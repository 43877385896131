class NumberFormat {
  static format (number, decimals, thousandSeparator, decimalSeparator) {
    var re = '\\d(?=(\\d{' + (3) + '})+' + (decimals > 0 ? '\\D' : '$') + ')',
        num = number.toFixed(Math.max(0, ~~decimals));

    return (decimalSeparator ? num.replace('.', decimalSeparator) : num).replace(new RegExp(re, 'g'), '$&' + (thousandSeparator || ','));
  }
  static round (value, exp) {
    if (typeof exp === 'undefined' || +exp === 0)
    return Math.round(value);

    value = +value;
    exp = +exp;

    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
        return NaN;

    // Shift
    value = value.toString().split('e');
    value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));

    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
  }
}
export {NumberFormat};