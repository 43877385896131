import BaseModel from './BaseModel';
//import Vue from 'vue';
//import lodash from 'lodash';
//import {DataConverter as CategoryExtraDataConverter} from '@/FirestoreDataConverters/CategoryExtra.js';
import {DataConverter/*, Reservation as ReservationData*/} from '@/FirestoreDataConverters/Reservation.js';
import {DataConverter as DataConverterPayment/*, Reservation as ReservationData*/} from '@/FirestoreDataConverters/Payment.js';
import {DataConverter as DataConverterHistory/*, Reservation as ReservationData*/} from '@/FirestoreDataConverters/History.js';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import Vue from 'vue'
import User from '@/models/User.js';
import Chance from "chance";
import {NumberFormat} from "@/helpers/NumberFormat";
import _ from 'lodash';

export default class Reservation extends BaseModel{
  constructor(options) {
    super(options);
  }
  findByDate(date) {
    let startDate = firebase.firestore.Timestamp.fromDate(date.startOf('day').toDate())
    let endDate = firebase.firestore.Timestamp.fromDate(date.endOf('day').toDate())
    return this.db.collection('reservations')
      .where('date', '>=', startDate)
      .where('date', '<=', endDate)
      .withConverter(DataConverter)
  }
  search(filters) {
    let ref = this.db.collection('reservations')
    let query
    if (_.has(filters, 'startDate') && _.has(filters, 'endDate')) {
      const startDate = firebase.firestore.Timestamp.fromDate(filters.startDate.startOf('day').toDate())
      const endDate = firebase.firestore.Timestamp.fromDate(filters.endDate.endOf('day').toDate())
      query = ref
      .where('date', '>=', startDate)
      .where('date', '<=', endDate)
    } else {
      if (_.has(filters, 'code')) {
        query = ref.where('code', '==', filters.code)
      }
      if (_.has(filters, 'email')) {
        query = ref.where('customerEmail', '==', filters.email)
      }
    }
    return query.withConverter(DataConverter)
  }
  findByRangeDates(start, end) {
    let startDate = firebase.firestore.Timestamp.fromDate(start.startOf('day').toDate());
    let endDate = firebase.firestore.Timestamp.fromDate(end.endOf('day').toDate());
    return this.db.collection('reservations')
      .where('date', '>=', startDate)
      .where('date', '<=', endDate)
      .withConverter(DataConverter)
  }
  getById(docId) {
    return this.db.collection('reservations').doc(docId).withConverter(DataConverter);
  }
  update(docId, data) {
    return this.db.collection('reservations').doc(docId).update(data);
  }
/////////////////
  updateGuides(id, name){
  return this.db.collection('reservations').doc(id).update({
    'nameGuides': name})
  }
////////////////
  async updateCustomer(docId, data, oldData) {
    const fields = this.getDiffFieldsV2(data, oldData)
    if (JSON.stringify(fields) !== '{}') {
      await this.db.collection('reservations').doc(docId).update({
        customerName:data.name,
        customerLastname:data.lastname,
        customerEmail:data.email,
        customer:data,
      });
      let history = {
        icon:'mdiPencil',
        iconAlternative:'mdi-pencil',
        style:'info',
        type:'updatePaymentStatus',
        title:'Actualización del Cliente',
        description:'Se actualizaron datos del cliente' ,
        fields:fields
      }
      await this.addHistory(docId, history)
    }
  }
  async updateWithHistory(docId, newData, oldData) {
    let fields = {}
    for (const property in newData) {
      var typeField  = typeof newData[property]
      if (typeof newData[property].seconds !== 'undefined') {
        if (newData[property].seconds != oldData[property].seconds) {
          fields[property] = {
            new : newData[property],
            old : oldData[property],
          }
        }
      } else if (typeField === 'object' || typeField === 'array') {
        console.log('alalaa')
        console.log('newData', JSON.stringify(newData[property]))
        console.log('oldData', JSON.stringify(oldData[property]))
        if (JSON.stringify(newData[property]) != JSON.stringify(oldData[property])) {
          fields[property] = {
            new : newData[property],
            old : oldData[property],
          }
        }
      } else if (newData[property] != oldData[property]) {
        fields[property] = {
          new : newData[property],
          old : oldData[property],
        }
      }
    }
    console.log('akis', fields)
    if (Object.keys(fields).length !== 0) {
      console.log('fields', fields)
      await this.update(docId, newData)
      let data = {
        icon:'mdiPencil',
        iconAlternative:'mdi-pencil',
        style:'info',
        type:'updateReservation',
        title: 'Modificación',
        description:'Se editaron datos de la reservacion',
        fields:fields
      }
      return this.addHistory(docId, data)
    }
  }
  async confirmationInfoAndPickup(docId, data) {
    let rData = {
      confirmationInfo:data.confirmationInfo,
      confirmationDescription:data.observations,
    }
    if (data.pickupTime) {
      let now = Vue.moment();
      let date = now.format('YYYY-MM-DD') + ' ' + data.pickupTime;
      rData.pickupDateTime = firebase.firestore.Timestamp.fromDate(Vue.moment(date).toDate())
    }
    await this.update(docId, rData)
    let historyPickup = {
      title:'Pickup',
      description:'Se asigno el pickup',
      //createdDate:now.toDate()
    }
    let historyConfirmation = {
      title:'Confirmación',
      description:data.observations,
      //createdDate:now.toDate()
    }
    await this.addHistoryPickup(docId, historyPickup)
    await this.addHistoryConfirmation(docId, historyConfirmation)
  }
  /**
   * style : default, warning, danger, success, info
   * icon
   * createdDate *** automaticamente se genera
   * title
   * description
  */
  async addHistory(docId, history) {
    let currentUser = firebase.auth().currentUser;
    let usersModel = new User();
    let user
    await usersModel.findByUID(currentUser.uid).get().then(qSnapshot => {
      if (!qSnapshot.empty) {
        qSnapshot.forEach(doc => {
          if (doc.exists) {
            user = doc.data();
          }
        })
      }
    })
    let data = {
      createdDate:firebase.firestore.Timestamp.fromDate(Vue.moment().toDate()),
      userUID:user.uid,
      user:user.name + ' ' + user.lastname,
      userEmail:user.email,
      userRole:user.role,
      ... history
    }
    return this.db.collection('reservations').doc(docId).collection('history').doc().set(data)
  }
  addHistoryPickup(docId, history) {
    let data = {
      icon:'mdiTaxi',
      iconAlternative:'mdi-taxi',
      style:'success',
      title:history.title,
      description:history.description,
      fields:{}
    }
    return this.addHistory(docId, data)
  }
  addHistoryConfirmation(docId, history) {
    let data = {
      icon:'mdiCheckCircle',
      iconAlternative:'mdi-check-circle',
      style:'success',
      title:history.title,
      description:history.description,
      fields:{}
    }
    return this.addHistory(docId, data)
  }
  addHistoryPayment(docId, history) {
    let data = {
      icon:history.icon,
      iconAlternative:history.iconAlternative,
      type:history.type || 'payment',
      style:history.style,
      title:history.title,
      description:history.description,
      files:history.files || [],
      fields:history.fields || {}
    }
    return this.addHistory(docId, data)
  }
  addHistorySendCoupon(docId, history) {
    let data = {
      icon:'mdiPaperclip',
      iconAlternative:'mdi-paperclip',
      style:'info',
      title:history.title,
      description:history.description,
      files:history.files || [],
      fields:{}
    }
    return this.addHistory(docId, data)
  }

  addHistoryAddIdentitificacion(docId, history) {
    let data = {
      icon:'mdiPaperclip',
      iconAlternative:'mdi-paperclip',
      style:'secondary',
      type:'updateID',
      title:history.title,
      description:history.description,
      files:history.files || [],
      fields:{}
    }
    return this.addHistory(docId, data)
  }

  async updateStatus(reservation, data) {
    if (reservation.status != data.status) {
      await this.update(reservation.id, { status:data.status })
      let history = {
        icon:'mdiCheckCircle',
        iconAlternative:'mdi-check-circle',
        style:'info',
        title:'Actualización de status',
        type: 'updateStatus',
        description:'Se modifico el status de la reservación',
        fields:{
          status: {
            old: reservation.status,
            new: data.status
          }
        }
      }
      await this.addHistory(reservation.id, history)
      return true
    }
  }

  async updateStatusToCheckin(reservation, data) {
    if (reservation.status != data.status) {
      await this.update(reservation.id, { status:data.status })
      let history = {
        icon:'mdiCheckCircle',
        iconAlternative:'mdi-check-circle',
        style:'info',
        title:'Checkin',
        type: 'checkin',
        description:'Actualización de status a Checkin',
        fields:{
          status: {
            old: reservation.status,
            new: data.status
          }
        }
      }
      await this.addHistory(reservation.id, history)
      return true
    }
  }

  async updatePaymentStatus(reservation, data) {
    if (reservation.paymentStatus != data.paymentStatus) {
      await this.update(reservation.id, { paymentStatus:data.paymentStatus })
      let history = {
        icon:'mdiPencil',
        iconAlternative:'mdi-pencil',
        style:'info',
        type:'updatePaymentStatus',
        title:'Actualización de status de pago',
        description:data.description,
        fields:{
          status: {
            old: reservation.paymentStatus,
            new: data.paymentStatus
          }
        }
      }
      await this.addHistory(reservation.id, history)
      return true
    }
  }

  getPayments(docId) {
    return this.db.collection('reservations').doc(docId)
      .collection('payments')
      .orderBy('createdDate', 'desc')
      .withConverter(DataConverterPayment);
  }

  getHistory(docId) {
    return this.db.collection('reservations').doc(docId)
      .collection('history')
      .orderBy('createdDate', 'desc')
      .withConverter(DataConverterHistory);
  }
  async updatePayment(reservationId, payment, paymentOld) {
    let fee = payment.fee || 0;
    if (typeof fee !== 'number') {
      fee = parseFloat(fee);
    }
    let paymentData = {
      description:payment.description,
    }
    if (payment.gatewayPayment == 'courtesy') {
      paymentData.authorizedBy = payment.authorizedBy
    } else {
      paymentData.transactionId = payment.transactionId || ''

      paymentData.fee = parseFloat(fee)
      // paymentData.amount = parseFloat(payment.amount)
      // paymentData.exchangeRate = parseFloat(payment.exchangeRate)
      
      // paymentData.currency = payment.currency
      paymentData.image = payment.image || null
    }
    if (payment.transferType) {
      paymentData.transferType = payment.transferType;
    }
    /*if (paymentOld.image) {
      const refFile = firebase.storage().refFromURL(paymentOld.image)
      await refFile.delete()
    }*/
    await this.db.collection('reservations')
      .doc(reservationId)
      .collection('payments')
      .doc(payment.id)
      .update(paymentData)
    let historyPayment = {
      title:`Se modifico el Pago de $${payment.amount} ${payment.currency}`,
      icon:'mdiCashUsdOutline',
      iconAlternative:'mdi-cash',
      style:'success',
      type:'paymentUpdate',
      description:payment.description,
      fields:this.getDiffFieldsV2(payment, paymentOld),
      files:[payment.image]
    }
    await this.addHistoryPayment(reservationId, historyPayment)
  }
  async addPayment(reservationId, payment) {
    let fee = payment.fee || 0;
    if (typeof fee !== 'number') {
      fee = parseFloat(fee);
    }
    let paymentData = {
      createdDate:firebase.firestore.Timestamp.fromDate(Vue.moment().toDate()),
      date:firebase.firestore.Timestamp.fromDate(payment.date),
      gatewayPayment:payment.gatewayPayment,
      
      description:payment.description,
    }
    if (payment.gatewayPayment == 'courtesy') {
      paymentData.authorizedBy = payment.authorizedBy
    } else {
      paymentData.transactionId = payment.transactionId || ''

      paymentData.fee = parseFloat(fee)
      paymentData.amount = parseFloat(payment.amount)
      paymentData.exchangeRate = parseFloat(payment.exchangeRate)
      
      paymentData.currency = payment.currency
      paymentData.image = payment.image || null
    }
    if (payment.transferType) {
      paymentData.transferType = payment.transferType;
    }
    await this.db.collection('reservations')
      .doc(reservationId)
      .collection('payments')
      .add(paymentData)
    let historyPayment = {
      title:`Pago de $${payment.amount} ${payment.currency}`,
      icon:'mdiCashUsdOutline',
      iconAlternative:'mdi-cash',
      style:'success',
      type:'payment',
      description:payment.description,
      files:[payment.image]
    }
    await this.addHistoryPayment(reservationId, historyPayment)
  }
  /***
   * Valida el total de los pagos para actualizar el status
   * de pago de la reservacion
   * 
   */
  async checkPayments(reservation) {
    const payments = await this.db
      .collection('reservations')
      .doc(reservation.id)
      .collection('payments').get()
    let totalPayments = 0
    payments.forEach(doc => {
      const payment = doc.data()
      if (payment.gatewayPayment != 'courtesy') {
        totalPayments += NumberFormat.round(payment.amount * payment.exchangeRate, 2)
      }
    })
    const balance = NumberFormat.round(reservation.total * reservation.exchangeRate, 2) - totalPayments
    if(balance <= 0) {
      await this.updatePaymentStatus(reservation, {
        paymentStatus: 'confirmed',
        description: 'Actualizacion de status por sistema al agregar un pago'
      })
    } else if(balance > 0) {
      await this.updatePaymentStatus(reservation, {
        paymentStatus: 'balance',
        description: 'Actualizacion de status por sistema al agregar un pago'
      })
    }
  }
  async deletePayment(reservationId, paymentId, reason) {
    const docRef = this.db.collection('reservations')
      .doc(reservationId)
      .collection('payments')
      .doc(paymentId)
      .withConverter(DataConverterPayment)
    const payment = await docRef.get()
    let historyPayment = {
      title:`Eliminacion de pago $${payment.data().amount} ${payment.data().currency}`,
      icon:'mdiDelete',
      iconAlternative:'mdi-delete',
      type:'paymentDelete',
      style:'red',
      description:reason,
      files:[],
      fields:JSON.stringify(payment.data()),
    }
    if (payment.data().image) {
      const refFile = firebase.storage().refFromURL(payment.data().image)
      await refFile.delete()
    }
    await docRef.delete()
    await this.addHistoryPayment(reservationId, historyPayment)
  }

  uploadImagePayment(reservationId, image) {
    let chance = new Chance();
    let random = chance.string({
      length: 5,
      //alpha: true,
      pool: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    });
    let splitNameFile = image.name.split('.');
    let name = 'payment-'+splitNameFile[0];
    let extension = splitNameFile[splitNameFile.length - 1];
    let fullpath = 'reservations/'+reservationId+'/'+name+'-'+random+'.'+extension;
    return {
      image:{fullpath:fullpath,name:name},
      ref:firebase.storage().ref(fullpath).put(image)
    };
  }

  uploadIdentification(reservationId, image, idName) {
    /*let chance = new Chance();
    let random = chance.string({
      length: 5,
      //alpha: true,
      pool: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    });*/
    let splitNameFile = image.name.split('.');
    //let name = 'payment-'+splitNameFile[0];
    let extension = splitNameFile[splitNameFile.length - 1];
    let fullpath = 'reservations/'+reservationId+'/identifications/'+idName+'.'+extension;
    return {
      image:{fullpath:fullpath, name:idName},
      ref:firebase.storage().ref(fullpath).put(image)
    };
  }

  async addIdentification(reservationId, docId, data) {
    await this.db.collection('reservations')
      .doc(reservationId)
      .collection('ids')
      .doc(docId)
      .set(data)
    return this.addHistoryAddIdentitificacion(reservationId, {
      title: 'Identificaciones',
      //description: `Se agrego una Identificación (${data.pax})`,
      description: `Se actualizaron las identificaciones`,
      files: [data.url]
    })
  }

  getIdentifications(docId) {
    return this.db.collection('reservations').doc(docId)
      .collection('ids')
  }
  async getDataReservationsStatusReport(filters) {
    const snapResult = await this.findByRangeDates(filters.startDate, filters.endDate).get()
    let result = {
      reservations: [],
      totalMXN: 0,
      totalUSD: 0,
      balanceUSD: 0,
      balanceMXN: 0,
      amountPaidMXN: 0,
      amountPaidUSD: 0,
    }
    const filterTourId = filters.tourId || false
    const filterPanel = filters.panel || false
    const filterRate = filters.rate || false
    const filterStatus = filters.status || false
    // let addItem = filterTourId || filterPanel || filterRate ? false : true
    for (const doc of snapResult.docs) {
      let addItemTour = true
      let addItemPanel = true
      let addItemRate = true
      let addItemStatus = true
      const data = doc.data()
      const tourId = data.tourId || false
      const panel = data.panel
      const paymentStatus = data.paymentStatus
      const rate = data.typeRate
      if (filterTourId) {
        addItemTour = filterTourId === tourId 
      }
      if (filterPanel ) {
        addItemPanel = filterPanel === panel
      }
      if (filterStatus ) {
        addItemStatus = filterStatus === paymentStatus
      }
      const couponNational = data.couponNational || false
      if (filterRate) {
        if (filterRate == 'coupon') {
          addItemRate = couponNational ? true : false
        } else if (filterRate == 'foreign' || filterRate == 'local') {
          addItemRate = rate === filterRate && !couponNational
        } 
      }
      if (tourId && addItemTour && addItemPanel && addItemRate && addItemStatus) {
        const snap = await this.getPayments(doc.id).get()
        let totalPayments = 0
        snap.forEach(docPayment => {
          const payment = docPayment.data()
          if (payment.gatewayPayment != 'courtesy') {
            totalPayments += payment.getPaidAmount() * payment.getExchangeRate()
          }
        })
        const balance = NumberFormat.round(data.total, 2) - NumberFormat.round(totalPayments, 2)
        if (data.currency == 'usd') {
          result.balanceUSD += balance
          result.amountPaidUSD += NumberFormat.round(totalPayments, 2)
          result.totalUSD += NumberFormat.round(data.total, 2)
        } else {
          result.balanceMXN += balance
          result.amountPaidMXN += NumberFormat.round(totalPayments, 2)
          result.totalMXN += NumberFormat.round(data.total, 2)
        }
        result.reservations.push({
          reservation: data,
          balance: balance,
          amountPaid: NumberFormat.round(totalPayments, 2),
        })
      }
    }
    return result
  }
  async getDataTourReport(filters) {
    const snapResult = await this.findByRangeDates(filters.startDate, filters.endDate).get()
    let result = {
      tours: {},
      reservations: 0,
      adults: 0,
      elderlies: 0,
      children: 0,
      infants: 0,
    }
    const filterTourId = filters.tourId || false
    const filterPanel = filters.panel || false
    const filterRate = filters.rate || false
    const filterStatus = filters.status || false
    // let addItem = filterTourId || filterPanel || filterRate ? false : true
    for (const doc of snapResult.docs) {
      let addItemTour = true
      let addItemPanel = true
      let addItemRate = true
      let addItemStatus = true
      const data = doc.data()
      const tourId = data.tourId || false
      const panel = data.panel
      const status = data.status
      const rate = data.typeRate
      if (filterTourId) {
        addItemTour = filterTourId === tourId 
      }
      if (filterPanel ) {
        addItemPanel = filterPanel === panel
      }
      if (filterStatus ) {
        addItemStatus = filterStatus === status
      }
      const couponNational = data.couponNational || false
      if (filterRate) {
        if (filterRate == 'coupon') {
          addItemRate = couponNational ? true : false
        } else if (filterRate == 'foreign' || filterRate == 'local') {
          addItemRate = rate === filterRate && !couponNational
        } 
      }
      if (tourId && addItemTour && addItemPanel && addItemRate && addItemStatus) {
        if (Object.prototype.hasOwnProperty.call(result.tours, data.tourId)) {
          result.tours[doc.data().tourId].tour = data.tour
          result.tours[doc.data().tourId].reservations ++
          result.tours[doc.data().tourId].items.push(data)
          result.tours[doc.data().tourId].adults += data.adults
          result.tours[doc.data().tourId].elderlies += data.elderlies
          result.tours[doc.data().tourId].children += data.children
          result.tours[doc.data().tourId].infants += data.infants
          result.reservations++
          result.adults += data.adults
          result.elderlies += data.elderlies
          result.children += data.children
          result.infants += data.infants
        } else {
          result.tours[doc.data().tourId] = {
            tour: data.tour,
            reservations:1,
            items:[doc.data()],
            adults: data.adults,
            elderlies: data.elderlies,
            children: data.children,
            infants: data.infants,
          }
          result.reservations++
          result.adults += data.adults
          result.elderlies += data.elderlies
          result.children += data.children
          result.infants += data.infants
        }
      }
    }
    return result
  }
  async getDataPaymentReport(filters) {
    const snapResult = await this.findByRangeDates(filters.startDate, filters.endDate).get()
    let result = {
      payments: [],
      totalUSD: 0,
      totalMXN: 0,
    }
    const filterTourId = filters.tourId || false
    const filterPanel = filters.panel || false
    const filterRate = filters.rate || false
    const filterGatewayPayment = filters.gatewayPayment || false
    // const filterStatus = filters.status || false
    for (const doc of snapResult.docs) {
      let addItemTour = true
      let addItemPanel = true
      let addItemRate = true
      // let addItemStatus = true
      const data = doc.data()
      const tourId = data.tourId || false
      const panel = data.panel
      // const status = data.status
      const rate = data.typeRate
      if (filterTourId) {
        addItemTour = filterTourId === tourId 
      }
      if (filterPanel ) {
        addItemPanel = filterPanel === panel
      }
      /*if (filterStatus ) {
        addItemStatus = filterStatus === status
      }*/
      const couponNational = data.couponNational || false
      if (filterRate) {
        if (filterRate == 'coupon') {
          addItemRate = couponNational ? true : false
        } else if (filterRate == 'foreign' || filterRate == 'local') {
          addItemRate = rate === filterRate && !couponNational
        } 
      }
      if (tourId && addItemTour && addItemPanel && addItemRate /*&& addItemStatus*/) {
        // buscamos los pagos
        const snapPayments = await this.getPayments(data.id).get()
        if (!snapPayments.empty) {
          for (const paymentDoc of snapPayments.docs) {
            let addItemGatwayPaymen = true
            const paymentData = paymentDoc.data()
            if (filterGatewayPayment) {
              addItemGatwayPaymen = filterGatewayPayment == paymentData.gatewayPayment
            }
            if (addItemGatwayPaymen) {
              if (paymentData.getCurrency() == 'mxn') {
                result.totalMXN += paymentData.getPaidAmount()
              } else {
                result.totalUSD += paymentData.getPaidAmount()
              }
              result.payments.push({
                tour:data.tour,
                reservationId:data.id,
                reservationCode:data.code,
                panel:data.panel,
                date:data.date,
                payment: paymentData
              })
            }
          }
        }
      }
    }
    return result
  }
  async getDataDailyReport(date) {
    let items = [];
    const snap = await this.findByDate(date).get()
    const totals = {
      totalPaypalUSD: 0,
      totalPaypalMXN: 0,
      totalPaypalRqUSD: 0,
      totalPaypalRqMXN: 0,
      totalCashUSD: 0,
      totalCashMXN: 0,
      totalEvopayRq: 0,
      totalClipTerminal: 0,
      totalClipRq: 0,
      totalTpvBank: 0,
      totalBankCheque: 0,
      totalTransferDeposit: 0,
    }
    for (const doc of snap.docs) {
      const payments = await doc.ref
        .collection('payments')
        .orderBy('createdDate', 'desc')
        .withConverter(DataConverterPayment)
        .get()

      const paymentsGroup = this.groupPaymentsForDailyReport(payments)
      const data = doc.data()
      const pax = `${data.adults ? data.adults : 0 }|${data.elderlies ? data.elderlies : 0 }|${data.children ? data.children : 0 }|${data.infants ? data.infants : 0 }|`
      
      totals.totalPaypalUSD += paymentsGroup.paypal.usd ? paymentsGroup.paypal.usd : 0
      totals.totalPaypalMXN += paymentsGroup.paypal.mxn ? paymentsGroup.paypal.mxn : 0
      totals.totalPaypalRqUSD += paymentsGroup.paypalRq.usd ? paymentsGroup.paypalRq.usd : 0
      totals.totalPaypalRqMXN += paymentsGroup.paypalRq.mxn ? paymentsGroup.paypalRq.mxn : 0
      totals.totalCashUSD += paymentsGroup.cash.usd ? paymentsGroup.cash.usd : 0
      totals.totalCashMXN += paymentsGroup.cash.mxn ? paymentsGroup.cash.mxn : 0
      totals.totalEvopayRq += paymentsGroup.evopayRq.mxn ? paymentsGroup.evopayRq.mxn : 0
      totals.totalClipTerminal += paymentsGroup.clipTerminal.mxn ? paymentsGroup.clipTerminal.mxn : 0
      totals.totalClipRq += paymentsGroup.clipRq.mxn ? paymentsGroup.clipRq.mxn : 0
      totals.totalTpvBank += paymentsGroup.tpvBank.mxn ? paymentsGroup.tpvBank.mxn : 0
      totals.totalBankCheque += paymentsGroup.bankCheque.mxn ? paymentsGroup.bankCheque.mxn : 0
      totals.totalTransferDeposit += paymentsGroup.transferDeposit.mxn ? paymentsGroup.transferDeposit.mxn : 0
      items.push({
        code: data.code,
        tour: data.tour,
        pax: pax,
        payments: paymentsGroup,
      })
    }
    return { items, totals }
  }
  groupPaymentsForDailyReport(payments) {
    let groups = {};

    groups.paypal = {}
    groups.paypalRq = {}
    groups.cash = {}
    groups.evopayRq = {}
    groups.clipTerminal = {}
    groups.clipRq = {}
    groups.tpvBank = {}
    groups.bankCheque = {}
    groups.transferDeposit = {}
    groups.courtesy = {}
    for (const doc of payments.docs) {
      const data = doc.data()
      const currency = data.getCurrency()
      switch (data.gatewayPayment) {
        case 'paypal':
          if (_.isUndefined(groups.paypal[currency])) {
            groups.paypal[currency] = data.getPaidAmount()
          } else {
            groups.paypal[currency] += data.getPaidAmount()
          }
          break
        case 'paypal-rq':
          if (_.isUndefined(groups.paypalRq[currency])) {
            groups.paypalRq[currency] = data.getPaidAmount()
          } else {
            groups.paypalRq[currency] += data.getPaidAmount()
          }
          break
        case 'evopay-rq':
          if (_.isUndefined(groups.evopayRq[currency])) {
            groups.evopayRq[currency] = data.getPaidAmount()
          } else {
            groups.evopayRq[currency] += data.getPaidAmount()
          }
          break
        case 'clip-terminal':
          if (_.isUndefined(groups.clipTerminal[currency])) {
            groups.clipTerminal[currency] = data.getPaidAmount()
          } else {
            groups.clipTerminal[currency] += data.getPaidAmount()
          }
          break
        case 'clip-rq':
          if (_.isUndefined(groups.clipRq[currency])) {
            groups.clipRq[currency] = data.getPaidAmount()
          } else {
            groups.clipRq[currency] += data.getPaidAmount()
          }
          break
        case 'tpv-bank':
          if (_.isUndefined(groups.tpvBank[currency])) {
            groups.tpvBank[currency] = data.getPaidAmount()
          } else {
            groups.tpvBank[currency] += data.getPaidAmount()
          }
          break
        case 'bank-cheque':
          if (_.isUndefined(groups.bankCheque[currency])) {
            groups.bankCheque[currency] = data.getPaidAmount()
          } else {
            groups.bankCheque[currency] += data.getPaidAmount()
          }
          break
        case 'cash':
          if (_.isUndefined(groups['cash'][currency])) {
            groups.cash[currency] = data.getPaidAmount()
          } else {
            groups.cash[currency] += data.getPaidAmount()
          }
          break
        case 'transfer-deposit':
          if (_.isUndefined(groups.transferDeposit[currency])) {
            groups.transferDeposit[currency] = data.getPaidAmount()
          } else {
            groups.transferDeposit[currency] += data.getPaidAmount()
          }
          break
        case 'courtesy':
          groups.courtesy.authorizedBy = data.authorizedBy
          break
        default :
          break
      }
    }
    return groups;
  }

  delete(reservationId) {
    return new Promise((resolve, reject) => {
      this.getPayments(reservationId).get().then(async (payments) => {
        if (!payments.empty) {
          return reject({
            status: 'Error',
            message: 'No puede eliminar una reservación con pagos',
          })
        }
        const historySnap = await this.getHistory(reservationId).get()
        for( const h of historySnap.docs) {
          await h.ref.delete()
        }
        await this.db.collection("reservations").doc(reservationId).delete()
        return resolve({
          status: 'OK'
        })
      })
    })
  }
  /**
   * Agrega las responsivas a la reservacion
   * reservationId string DocumentId
   * responsives Array [{
   *   checkin: true o false,
   *   fullName: string nombre completo del cliente,
   *   sign: string base64 image,
   *   tour: string nombre del tour
   * }]
   * responsivesOld Array [{
   *   Lo mismo que responsives 
   * }]
   **/  
  async updateResponsivesSigned(reservationId, responsives, childrenNames, oldResponsives, oldChildrenNames) {
    await this.update(reservationId, { responsives:responsives, childrenNames: childrenNames })
    let history = {
      icon:'mdiPencil',
      iconAlternative:'mdi-pencil',
      style:'info',
      type:'responsives',
      title:'Actualización de responsivas',
      description:'Actualizacion de responsivas',
      fields:{
        responsives: this.getDiffFields(oldResponsives, responsives),
        childrenNames: this.getDiffFields(oldChildrenNames, childrenNames)
      }
    }
    await this.addHistory(reservationId, history)
  }
  getDiffFields(oldFields, newFields) {
    return { old: oldFields, new : newFields}
  }
  getDiffFieldsV2(newData, oldData) {
    let result = {}
    for(const prop in newData) {
      if (typeof oldData[prop] !== 'undefined') {
        if (oldData[prop] !== newData[prop]) {
          result[prop] = {
            old: oldData[prop],
            new: newData[prop],
          }
        }
      } else {
        result[prop] = {
          old: '',
          new: newData[prop],
        }
      }
    }
    return result
  }
  getByUserId(userId) {
    return this.db.collection('reservations')
      .where('userId', '==', userId)
      .orderBy('date', 'desc')
      .withConverter(DataConverter)
  }
  getByUserIdAndStatusCheckin(userId) {
    return this.db.collection('reservations')
      .where('userId', '==', userId)
      .where('status', '==', 'checkin')
      .orderBy('date', 'desc')
      .withConverter(DataConverter)
  }
  getReservationsDiversByDate(date) {
    let startDate = firebase.firestore.Timestamp.fromDate(date.startOf('day').toDate());
    let endDate = firebase.firestore.Timestamp.fromDate(date.endOf('day').toDate());
    return this.db.collection('reservations')
    .where('date', '>=', startDate)
    .where('date', '<=', endDate)
    .where('panel', '==', 'divers')
    .orderBy('date', 'asc')
    .withConverter(DataConverter)
  }
}