import moment from 'moment'

class History {
  id;
  constructor(data) {
    this.id = data.id
    this.createdDate = data.createdDate
    this.description = data.description
    this.fields = data.fields
    this.icon = data.icon
    this.type = data.type
    this.iconAlternative = data.iconAlternative
    this.style = data.style
    this.title = data.title
    this.user = data.user
    this.userEmail = data.userEmail
    this.files = data.files || null
    this.userRole = data.userRole
    this.userUID = data.userUID
  }
  getFormatedDate(format) {
    format = format || 'HH:mm:ss MMM DD, YYYY'
    return moment.unix(this.createdDate.seconds).format(format)
  }
}
var DataConverter = {
  toFirestore: function(item) {
    return {...item}
  },
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    data.id = snapshot.id
    return new History(data)
  }
}
export {History, DataConverter} 